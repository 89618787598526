.event-details-main {
  border-radius: 9px;
}


@media (min-width: 768px)  {
  .event-details-main {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .event-details-container {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 50px;
  }

  .event-page-wrapper {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 30px;
    padding-bottom: 115px;
  }

}

@media (max-width: 767px)  {
  .event-page-wrapper > *:first-child {
      margin-bottom: 15px;
  }

  .event-details-main {

  }
  .event-container {
    padding-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0px !important;
  }

  .event-information {
    padding-bottom: 130px;
    padding-left: 15px;
  }

  .event-gallery {
    position: relative !important;
  }

  .event-details-container {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 25px;
  }

  .event-page-wrapper {
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 100px;
  }
}

.image-gallery-image img {
 max-height: 400px;
}

.event-gallery {
  height: 300px !important;
}

.event-sticky-container {
  height: 100%;
}

.event-gallery:hover {
  cursor: pointer;
}

.event-details-wrapper > *:not(:last-child) {
  margin-bottom: 25px;
}

.event-details-container {
  border-radius: 9px;
  background-color: white;
}

/*.event-details-panel {
  padding: 15px;
}*/

.event-details-pill-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}

.event-details-panel {
  padding-top: 30px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 15px;
}

.event-details-audience-attributes > *:not(:last-child) {
  margin-bottom: 10px;
}

.event-details-packages-content > :not(:last-child) {
  margin-bottom: 15px;
}


.checkbox-wrapper > *:not(:last-child){
  margin-bottom: 15px;
}


.event-details-pill-wrapper > * {
  margin-bottom: 15px;
  margin-right: 15px;
}
