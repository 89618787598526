.new-button {
  min-height: 40px;
  min-width: 143px;
  border: none;
  padding-left: 15px;
  padding-right: 15px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 4px;
  letter-spacing: 1px;
  font-family: Poppins,sans-serif;
  font-size: 14px !important;
  font-weight: 500;
}

.button-transparent {
  border: none;
}

.button-sm {
  min-height: 40px !important;
  padding-top: unset !important;
  padding-bottom: unset !important;
}

.new-button:disabled {
  cursor: not-allowed !important;
}

.button-primary {
  color: white;
  background-color: #EB5B49;
}

.button-primary-circle {
  height: 50px !important;
  width: 50px !important;
  border-radius: 100%  !important;
  border: none;
}

.button-primary-border {
  color: #EB5B49 !important;
  border: 1px solid #EB5B49;
  background-color: transparent;
}

.button-tertiary {
  color: white;
  background-color: #680894;
}


.button-quarternary {
  color: #680894;
  background-color: transparent;
  border: none;
  border: 1px solid #680894;
}

.button-quintary {
  color: #333333;
  background-color: transparent;
  border: none;
  border: 1px solid #333333;
}

.button-senary {
  color: white;
  background-color: transparent;
  border: none;
  border: 1px solid white;
}

.button-gray {
  color: #333333;
  background-color: white;
  border: 1px solid #E0E0E0;
}

.button-secondary {
  color: #EB5B49 !important;
  background-color: transparent;
  border: 1px solid #EB5B49;
}

.button-secondary-circle {
  height: 50px !important;
  width: 50px !important;
  border-radius: 100%  !important;
  color: #EB5B49 !important;
  background-color: transparent;
  border: 1px solid #EB5B49;
}

.new-button:hover {
  cursor: pointer;
}

@media (min-width: 768px) {
  .button-lg {
    min-width: 300px;
  }

  .button-md {
    min-width: 200px;
  }
}

@media (max-width: 767px) {
  .button-lg, .button-md {
    width: 100%;
  }
}

.button-full {
  width: 100%;
}

.button-gradient {
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  background-image: linear-gradient(to bottom right, #9B4CBF, #680894);
}
